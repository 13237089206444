<template>
  <div>
    <div class="row">
      <div class="col-12 mb-3">
        <transition name="slide-fade" appear>
          <v-select
            class="form-select"
            placeholder="Perfil"
            label="Nombre"
            :options="perfiles"
            :reduce="perfil => perfil.PerfilId"
            :searchable="false"
            :clearable="false"
            @option:selected="changedPerfil"
            v-model="perfilId"></v-select>
        </transition>
      </div>
    </div>
    <div class="row" v-if="perfilId && permisos">
      <transition name="slide-fade" appear>
        <div class="col-12">
          <div class="table-responsive site-table2-responsive">
            <h6>Permisos del Perfil</h6>
            <vue-good-table
            styleClass="vgt-table condensed site-table2"
            :columns="columns"
            :rows="permisos"
            :search-options="{
              enabled: true,
              trigger: 'enter',
              skipDiacritics: true,
              placeholder: 'Buscar permiso',
            }">
              <div slot="emptystate">
                <div class="empty-image">
                  <img src="@/assets/empty.svg" alt="Empty">
                  <p class="empty-description">Sin datos</p>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field !== 'Nombre'" >
                  <input
                    type="checkbox"
                    @change="changePermiso($event, props)"
                    :checked="props.row[props.column.field]"
                  />
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

export default {
  components: {
    VueGoodTable,
  },
  data() {
    return {
      loading: false,
      perfiles: [],
      perfilId: '',
      ver: false,
      columns: [
        {
          label: 'Permiso',
          field: 'Nombre',
        },
        {
          label: 'Ver',
          field: 'Ver',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '80px',
        },
        {
          label: 'Agregar',
          field: 'Agregar',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '80px',
        },
        {
          label: 'Editar',
          field: 'Editar',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '80px',
        },
        {
          label: 'Eliminar',
          field: 'Eliminar',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '80px',
        },
        {
          label: 'Imprimir',
          field: 'Imprimir',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '80px',
        },
        {
          label: 'Cargar',
          field: 'Cargar',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '80px',
        },
        {
          label: 'Descargar',
          field: 'Descargar',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '80px',
        },
        {
          label: 'Importar',
          field: 'Importar',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '80px',
        },
        {
          label: 'Exportar',
          field: 'Exportar',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '80px',
        },
        {
          label: 'Validar',
          field: 'Validar',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '80px',
        },
      ],
      permisos: [],
    };
  },
  mounted() {
    this.getPerfiles();
  },
  methods: {
    getPerfiles() {
      apiClient.get('/perfiles')
        .then((res) => {
          // eslint-disable-next-line prefer-destructuring
          this.perfiles = res.data;
        })
        .catch(() => {});
    },
    changedPerfil(selectedOption) {
      this.perfilId = selectedOption.PerfilId;
      this.getPermisos();
    },
    getPermisos() {
      apiClient.get('/get-permisos', {
        params: {
          perfilId: this.perfilId,
        },
      })
        .then((res) => {
          // eslint-disable-next-line prefer-destructuring
          this.permisos = res.data;
        })
        .catch(() => {});
    },
    changePermiso(e, props) {
      const checked = e.target.checked ? 1 : 0;
      // eslint-disable-next-line no-param-reassign
      props.row[props.column.field] = checked;
      this.updatePermiso(this.perfilId, props.row.PerfilPermisoId || 0, props.row.PermisoId, props.column.field, checked);
    },
    updatePermiso(perfilId, perfilPermisoId, permisoId, tipoPermiso, valorPermiso) {
      apiClient.put('/permisos', {
        perfilId,
        perfilPermisoId,
        permisoId,
        tipoPermiso,
        valorPermiso,
      })
        .then((res) => {
          if (res.status === 201) this.getPermisos();
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
